<template>
  <div>
    <multiselect
      v-model="store"
      :options="async (q) => loadStoreOptions(q, store)"
      :loading="loadingStoreOptions"
      :form-label="$t('store.label')"
      :filter-results="false"
      searchable
    />
    <fancy-input v-model="start" type="datetime-local" :label="$t('revenueStatistics.startDate')" />
    <fancy-input v-model="end" type="datetime-local" :label="$t('revenueStatistics.endDate')" />
    <simple-dropdown v-model="groupFormat" :label="$t('revenueStatistics.groupFormat.label')">
      <option v-for="(data, key) in groupFormats" :key="key" :value="data">
        {{ $t(`revenueStatistics.groupFormat.${key}`) }}
      </option>
    </simple-dropdown>
    <multiselect
      v-model="customerGroup"
      :options="async (q) => loadCustomerGroupOptions(q, customerGroup)"
      :loading="loadingCustomerGroupOptions"
      :form-label="$t('customerGroup.label')"
      :filter-results="false"
      searchable
    />
    <button class="btn mt-5" @click="fetchData">
      {{ $t('productStatistics.fetch') }}
    </button>
    <hr>
    <div v-if="statsData" class="overflow-scroll">
      <h2>{{ $t('productStatistics.netResults') }}</h2>
      <table class="whitespace-nowrap">
        <thead>
          <tr>
            <th /><!-- slot -->
            <th /><!-- product -->
            <th v-for="c in currencies" :key="c" :colspan="currencies.length * (vats.length + 1) * Object.keys(discounts).length * 2">
              {{ c }}
            </th>
          </tr>
          <tr>
            <th /><!-- slot -->
            <th>{{ $t('productStatistics.product') }}</th>
            <template v-for="c in currencies" :key="c">
              <template v-for="idx in vats.length + 1" :key="idx">
                <th>
                  {{ $t('productStatistics.numProducts') }}
                </th>
                <th>
                  {{ $t('productStatistics.undiscountedRevenue') }}
                </th>
              </template>
              <th v-for="d in discounts" :key="d.id" :colspan="(vats.length + 1) * 2" class="border-left">
                {{ d.name }}
              </th>
            </template>
          </tr>
          <tr>
            <th /><!-- slot -->
            <th /><!-- product -->
            <template v-for="_ in currencies.length" :key="_">
              <th :colspan="2">
                {{ $t('productStatistics.revenueTotal') }}
              </th>
              <th v-for="vat in vats" :key="vat" :colspan="2">
                {{ parseFloat(vat)*100 }}%
              </th>
              <template v-for="d in discounts" :key="d.id">
                <th :colspan="2" class="border-left">
                  {{ $t('productStatistics.discountTotal') }}
                </th>
                <th v-for="vat in vats" :key="vat" :colspan="2">
                  {{ parseFloat(vat)*100 }}%
                </th>
              </template>
            </template>
          </tr>
          <!--<tr>
            <th />
            <th>{{ $t('productStatistics.product') }}</th>
            <template v-for="_ in currencies.length" :key="_">
              <template v-for="idx in vats.length + 1" :key="idx">
                <th>
                  {{ $t('productStatistics.numProducts') }}
                </th>
                <th>
                  {{ $t('productStatistics.undiscountedRevenue') }}
                </th>
              </template>
            </template>
          </tr>-->
        </thead>
        <tbody>
          <template v-for="slot in timeSlots" :key="slot">
            <tr v-for="(productId, pIdx) in Object.keys(statsData[slot])" :key="productId" :class="{slotSection : pIdx === 0}">
              <td v-if="pIdx === 0" :rowspan="Object.keys(statsData[slot]).length" class="whitespace-nowrap">
                {{ format(parse(slot, groupFormat.chart, new Date()), groupFormat.display) }}
              </td>
              <td>{{ products[productId]?.name }}</td>
              <template v-for="currency in currencies" :key="productId + currency">
                <td>{{ statsData[slot][productId][currency].total.numProducts }}</td>
                <td>{{ formatPrice({amount: statsData[slot][productId][currency].total.netUndiscountedRevenue, currency }) }}</td>
                <template v-for="vat in vats" :key="productId + currency + vat">
                  <td>{{ statsData[slot][productId][currency].perVat[vat]?.numProducts ?? 0 }}</td>
                  <td>{{ formatPrice({amount: statsData[slot][productId][currency].perVat[vat]?.netUndiscountedRevenue, currency }) }}</td>
                </template>
                <template v-for="d in discounts" :key="productId + currency + d">
                  <td class="border-left">{{ statsData[slot][productId][currency].total.discounts[d.id]?.lineItemDiscountCount ?? 0 }}</td>
                  <td>{{ formatPrice({amount: statsData[slot][productId][currency].total.discounts[d.id]?.netDiscountAmount ?? 0, currency }) }}</td>
                  <template v-for="vat in vats" :key="productId + currency + d + vat">
                    <td>{{ statsData[slot][productId][currency].perVat[vat]?.discounts[d.id]?.lineItemDiscountCount ?? 0 }}</td>
                    <td>{{ formatPrice({amount: statsData[slot][productId][currency].perVat[vat]?.discounts[d.id]?.netDiscountAmount ?? 0, currency }) }}</td>
                  </template>
                </template>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import { ref } from 'vue';
import {
  subDays, format, formatISO, parse, startOfMonth,
} from 'date-fns';
import useLoadOptions from '@/hooks/api/loadOptions';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';

import axios from '@/services/axios';
import formatPrice from '@/utils/formatPrice';
import getIdFromIri from '@/utils/getIdFromIri';

export default {
  name: 'ProductStatisticsIndexPage',
  components: {
    Multiselect,
    FancyInput,
    SimpleDropdown,
  },
  setup() {
    const store = ref(null);
    const customerGroup = ref(null);
    const start = ref(format(startOfMonth(new Date()), 'yyyy-MM-dd\'T\'HH:mm'));
    const end = ref(format(subDays(new Date(), 1).setHours(23, 59, 59), 'yyyy-MM-dd\'T\'HH:mm'));
    const vats = ref([]);
    const timeSlots = ref([]);
    const statsData = ref(null);
    const currencies = ref([]);
    const productIds = ref([]);
    const discounts = ref({});
    const products = ref({});

    const groupFormats = {
      second: {
        backend: '%Y-%m-%dT%H:%i:%s+0100',
        chart: 'yyyy-MM-dd\'T\'HH:mm:ssx',
        display: 'yyyy-MM-dd\'T\'HH:mm:ss',
      },
      minute: {
        backend: '%Y-%m-%dT%H:%i+0100',
        chart: 'yyyy-MM-dd\'T\'HH:mmx',
        display: 'yyyy-MM-dd\'T\'HH:mm',
      },
      hour: {
        backend: '%Y-%m-%dT%H+0100',
        chart: 'yyyy-MM-dd\'T\'HHx',
        display: 'yyyy-MM-dd\'T\'HH',
      },
      day: {
        backend: '%Y-%m-%d+0100',
        chart: 'yyyy-MM-ddx',
        display: 'yyyy-MM-dd',
      },
      week: {
        backend: '%x-%v+0100',
        chart: 'RRRR-IIx',
        display: 'RRRR-II',
      },
      month: {
        backend: '%Y-%m+0100',
        chart: 'yyyy-MMx',
        display: 'yyyy-MM',
      },
      year: {
        backend: '%Y+0100',
        chart: 'yyyyx',
        display: 'yyyy',
      },
    };
    const groupFormat = ref(groupFormats.month);
    const {
      loadOptions: loadStoreOptions,
      loading: loadingStoreOptions,
    } = useLoadOptions('/stores', 'name');

    const {
      loadOptions: loadCustomerGroupOptions,
      loading: loadingCustomerGroupOptions,
    } = useLoadOptions('/customer_groups', 'name');

    const fetchData = async () => {
      const { data } = await axios.post('/statistics/products', {
        format: groupFormat.value.backend,
        start: start.value,
        end: end.value,
        store: store.value,
        customerGroup: customerGroup.value,
      });

      timeSlots.value = [];
      currencies.value = [];
      vats.value = [];
      const discountIds = [];

      Object.keys(data).forEach((s) => {
        timeSlots.value.push(s);
        const ps = data[s];
        Object.keys(ps).forEach((p) => {
          if (!productIds.value.includes(p)) {
            productIds.value.push(p);
          }
          const cs = data[s][p];
          Object.keys(cs).forEach((c) => {
            if (!currencies.value.includes(c)) {
              currencies.value.push(c);
            }
            const vs = data[s][p][c].perVat;
            Object.keys(vs).forEach((v) => {
              if (!vats.value.includes(v)) {
                vats.value.push(v);
              }
            });

            Object.keys(data[s][p][c].total.discounts).forEach((d) => {
              discountIds.push(d);
            });
          });
        });
      });

      timeSlots.value = timeSlots.value.sort();
      statsData.value = data;

      if (discountIds.length > 0) {
        const { data: discountData } = await axios.get('/discounts', {
          params: {
            id: [...new Set(discountIds.map((i) => getIdFromIri(i)))],
            pagination: false,
          },
        });
        discounts.value = {};
        discountData['hydra:member'].forEach((discount) => {
          discounts.value[discount.id] = discount;
        });
      }

      if (productIds.value.length > 0) {
        products.value = {};
        const chunkSize = 10;
        for (let i = 0; i < productIds.value.length; i += chunkSize) {
          const chunk = productIds.value.slice(i, i + chunkSize);
          axios.get('/products', {
            params: {
              id: [...new Set(chunk.map((iri) => getIdFromIri(iri)))],
              pagination: false,
            },
          }).then((response) => {
            const productData = response.data;
            productData['hydra:member'].forEach((product) => {
              products.value[product.id] = product;
            });
          });
        }
      }
    };

    return {
      loadStoreOptions,
      loadingStoreOptions,
      store,
      loadCustomerGroupOptions,
      loadingCustomerGroupOptions,
      customerGroup,
      start,
      end,
      groupFormat,
      groupFormats,
      fetchData,
      vats,
      timeSlots,
      statsData,
      currencies,
      formatPrice,
      parse,
      format,
      discounts,
      productIds,
      products,
    };
  },
  computed: {
    startDate() {
      return formatISO(new Date(this.start));
    },
    endDate() {
      return formatISO(new Date(this.end));
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  tr {
    &:hover {
      background-color: inherit;
    }
    &:nth-of-type(even):hover {
      background-color: var(--color-highlight);
    }
    td, th {
      padding: 1rem;
    }
    &.slotSection {
      border-top: 2px solid #000;
    }
    .border-left {
      border-left: 2px solid #000;
    }
    /*&:nth-child(3n) {
      border-bottom: 2px solid #000;
    }*/
  }
}
</style>
