<template>
  <modal-form
    ref="modal"
    v-slot="{ properties, errors, entity }"
    type="editDiscount"
    :normalizer="normalizer"
    :options="{
      properties: {
        name: '',
        applyToOrder: false,
        applyToSubProducts: false,
        cashierSelectable: true,
        discountType: 10,
        percentDiscount: null,
        absoluteDiscount: null,
      },
      postUri: '/discounts',
      label: $t('discount.label'),
    }"
  >
    <fancy-input v-model="properties.name" :error="errors.name" :label="$t('general.field.name')" />
    <fancy-checkbox v-model="properties.applyToOrder" :error="errors.applyToOrder" :label="$t('discount.applyToOrder.label')" />
    <fancy-checkbox v-if="!properties.applyToOrder" v-model="properties.applyToSubProducts" :error="errors.applyToSubProducts" :label="$t('discount.applyToSubProducts.label')" />
    <fancy-checkbox v-model="properties.cashierSelectable" :error="errors.cashierSelectable" :label="$t('discount.cashierSelectable.label')" />
    <simple-dropdown
      v-model="properties.discountType"
      :options="discountTypes"
      :error="errors.discountType"
      :label="$t('discount.type.label')"
      @update:model-value="discountTypeChanged($event, properties, entity)"
    />

    <price-form
      v-if="properties.discountType === 20 && properties.absoluteDiscount != null"
      v-model="properties.absoluteDiscount"
      :label="$t('discount.absoluteDiscount.label')"
      :error="errors.absoluteDiscount"
    />
    <fancy-input
      v-if="properties.discountType === 10"
      v-model="properties.percentDiscount"
      :error="errors.percentDiscount"
      :label="$t('discount.percentDiscount.label')"
      unit="%"
      multiplier="100"
    />
  </modal-form>
</template>

<script>
import { ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import FancyCheckbox from '@/components/utils/forms/FancyCheckbox.vue';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';
import PriceForm from '@/components/shared/forms/PriceForm.vue';

export default {
  name: 'EditDiscountModal',
  components: {
    PriceForm,
    SimpleDropdown,
    FancyCheckbox,
    FancyInput,
    ModalForm,
  },
  setup() {
    const { t } = useI18n();
    const modal = ref(null);
    const discountTypes = ref([
      { key: 'nothing', value: null, label: '' },
      { key: 'relative-discount', value: 10, label: t('discount.type.10') },
      { key: 'absolute-discount', value: 20, label: t('discount.type.20') },
    ]);

    const discountTypeChanged = (type, properties) => {
      console.log('test');
      if (type === 10) {
        properties.percentDiscount = 0;
        properties.absoluteDiscount = null;
      } else if (type === 20) {
        properties.percentDiscount = null;
        properties.absoluteDiscount = {
          currency: 'CHF',
          amount: 0,
        };
      }
    };

    const normalizer = (entity) => ({
      ...entity,
      absoluteDiscount: entity.absoluteDiscount != null ? {
        ...entity.absoluteDiscount,
        '@id': undefined,
        id: entity.absoluteDiscount?.['@id'],
      } : null,
    });

    return {
      modal,
      discountTypes,
      discountTypeChanged,
      normalizer,
    };
  },
};
</script>
