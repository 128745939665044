<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('discount.label', 2)" />
      <button
        class="small"
        @click="$store.dispatch('modals/openModal', { modal: 'editDiscount' , entity: {
          name: '',
          applyToOrder: false,
          applyToSubProducts: false,
          cashierSelectable: true,
          discountType: 10,
          absolutePercentage: null,
          relativePercentage: null,
        } })"
      >
        +
      </button>
    </div>

    <button
      v-if="Object.keys(filters).some((f) => !!filters[f])"
      class="white small mb-4 visible"
      @click="resetFilters(); loadEntities();"
      v-text="$t('discount.showAll')"
    />
    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      @click:edit="$store.dispatch('modals/openModal', { modal: 'editDiscount', entity: $event })"
      @click:delete="$store.dispatch('modals/openModal', { modal: 'deleteDiscount', entity: $event })"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
    >
      <template #cell(discount)="data">
        <template v-if="data.entity.discountType === 10">
          {{ data.entity.percentDiscount * 100 }}%
        </template>
        <template v-else-if="data.entity.discountType === 20">
          {{ formatPrice(data.entity.absoluteDiscount) }}
        </template>
      </template>
    </entity-table>

    <edit-discount-modal @update="loadEntities" />
    <delete-modal type="deleteDiscount" entity-translation-key="discount" @update="loadEntities" />
  </div>
</template>
<script>
import useLoadEntities from '@/hooks/api/loadEntities';
import useEntityFields from '@/hooks/api/fields';
import formatPrice from '@/utils/formatPrice';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditDiscountModal from '@/components/entities/discount/EditDiscountModal.vue';

export default {
  name: 'DiscountIndexPage',
  components: {
    EditDiscountModal,
    EntityTable,
    DeleteModal,
  },
  setup() {
    const { discount: fields } = useEntityFields();
    const {
      entities,
      filters,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      resetFilters,
    } = useLoadEntities('/discounts', { sort: 'name' });

    return {
      fields,
      entities,
      filters,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      formatPrice,
      resetFilters,
    };
  },
};
</script>
