<template>
  <div class="relative">
    <label :for="id" v-text="label" />
    <input
      :id="id"
      v-model="amount"
      :required="required"
      :readonly="readonly"
      step="0.01"
    >
    <select v-model="price.currency" :disabled="currencies.length === 1">
      <option
        v-for="currency in currencies"
        :key="id + '-' + currency"
        :value="currency"
        v-text="currency"
      />
    </select>
    <small v-if="error" class="text-red" v-text="error" />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import slugify from 'slugify';
import { useModel } from '@/hooks/model';

export default {
  name: 'PriceForm',
  props: {
    modelValue: { type: Object, default: () => ({ currency: 'CHF', amount: null }) },
    label: { type: String, default: null },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    error: { type: String, default: null },
  },
  setup(props, { emit }) {
    const id = slugify(props.label);
    const price = useModel(props, emit);

    const amount = computed({
      get: () => price.value.amount / 100,
      set: (val) => {
        price.value.amount = Math.round(val * 100);
      },
    });

    const currencies = ref(['CHF']);

    return {
      id,
      price,
      amount,
      currencies,
    };
  },
};
</script>

<style lang="scss" scoped>
select {
  @apply absolute right-0 w-14 bg-gray-100;

  bottom: 0;
  top: calc(1rem + 2px);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: none;

  &:not(:disabled) {
    cursor: pointer;
  }
}
</style>
