<template>
  <div class="chart">
    <canvas ref="chartElement" />
  </div>
</template>

<script>
import {
  Chart, CategoryScale, LinearScale, TimeScale, BarController, BarElement, LineController, LineElement, PointElement, Tooltip,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import {
  onMounted, ref, shallowRef,
} from 'vue';
import { format } from 'date-fns';

export default {
  name: 'InteractiveTimeBarChart',
  props: {
    load: {
      type: Function,
      required: true,
    },
    getTooltip: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const userTimeZone = format(new Date(), 'xxxx');
    const groupFormats = {
      second: {
        backend: `%Y-%m-%dT%H:%i:%s${userTimeZone}`,
        chart: 'yyyy-MM-dd\'T\'HH:mm:ssx',
        unit: 'second',
      },
      minute: {
        backend: `%Y-%m-%dT%H:%i${userTimeZone}`,
        chart: 'yyyy-MM-dd\'T\'HH:mmx',
        unit: 'minute',
      },
      hour: {
        backend: `%Y-%m-%dT%H${userTimeZone}`,
        chart: 'yyyy-MM-dd\'T\'HHx',
        unit: 'hour',
      },
      day: {
        backend: `%Y-%m-%d${userTimeZone}`,
        chart: 'yyyy-MM-ddx',
        unit: 'day',
      },
      week: {
        backend: `%x-%v${userTimeZone}`,
        chart: 'RRRR-IIx',
        unit: 'week',
      },
      month: {
        backend: `%Y-%m${userTimeZone}`,
        chart: 'yyyy-MMx',
        unit: 'month',
      },
      year: {
        backend: `%Y${userTimeZone}`,
        chart: 'yyyyx',
        unit: 'year',
      },
    };

    const timer = ref(null);
    const chartElement = ref(null);
    const chart = shallowRef(null);
    const ticksFormat = ref(groupFormats.hour);

    const fetchData = async (x, y) => props.load(
      ticksFormat.value,
      format(new Date(x), "yyyy-MM-dd'T'00:00:00.000xxx"),
      format(new Date(y), "yyyy-MM-dd'T'23:59:59.999xxx"),
    );

    const getTicksFormat = (min, max) => {
      const diff = (max - min) / 1000;
      if (diff > (365 * 86400)) { // 1 year
        return groupFormats.year;
      }
      if (diff > (30 * 86400)) { // 30 days
        return groupFormats.month;
      }
      if (diff > 84600) { // 1 day
        return groupFormats.day;
      }
      if (diff > (3 * 3600)) { // 3 hours
        return groupFormats.hour;
      }
      if (diff > 900) { // 15min
        return groupFormats.minute;
      }
      return groupFormats.second;
    };

    // reload statistic
    const reload = async (context, min, max) => {
      const ticks = getTicksFormat(min, max);
      ticksFormat.value = ticks;
      context.data.datasets = await fetchData(min, max);
      context.options.scales.x.time.unit = ticks.unit;
      context.stop(); // make sure animations are not running
      context.update('none');
    };

    const startFetch = ({ chart: context }) => {
      const { min, max } = context.scales.x;
      clearTimeout(timer.value);
      timer.value = setTimeout(() => {
        reload(context, min, max);
      }, 100);
    };

    const baseConfig = {
      type: 'bar',
      data: {
        datasets: [],
      },
      options: {
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
              onPanComplete: startFetch,
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              drag: {
                enabled: false,
              },
              pinch: {
                enabled: true,
              },
              mode: 'x',
              onZoomComplete: startFetch,
            },
          },
          title: {
            display: true,
            text: 'Chart.js Bar Chart - Stacked',
          },
          tooltip: {
            callbacks: {
              label(context) {
                return props.getTooltip(context);
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            offset: true,
            stacked: true,
            bounds: 'ticks',
            type: 'time',
            time: {
              round: true,
              minUnit: 'second',
              unit: 'day',
              displayFormats: {
                millisecond: 'HH:mm:ss.SSS',
                second: 'HH:mm:ss',
                minute: 'HH:mm',
                hour: 'd. MMM HH:mm',
                day: 'd. MMM yyyy',
              },
              tooltipFormat: 'dd.MM.yyyy HH:mm:ss',
            },
          },
          y: {
            offset: true,
            stacked: true,
            beginAtZero: true,
          },
        },
      },
    };

    onMounted(() => {
      Chart.register(CategoryScale, LinearScale, TimeScale, BarController, BarElement, LineController, LineElement, PointElement, Tooltip, zoomPlugin);
      chart.value = new Chart(
        chartElement.value,
        baseConfig,
      );
      reload(chart.value, Date.now() - 86400000, Date.now());
    });

    return {
      chartElement,
    };
  },
};
</script>
<style lang="scss" scoped>
.chart {
  position: relative;
  height: 75vh;
}
</style>
