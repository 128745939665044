<template>
  <div>
    <multiselect
      v-model="store"
      :options="async (q) => loadStoreOptions(q, store)"
      :loading="loadingStoreOptions"
      :form-label="$t('store.label')"
      :filter-results="false"
      searchable
    />
    <interactive-time-bar-chart
      v-if="store != null"
      :get-tooltip="getTooltip"
      :load="load"
    />
  </div>
</template>
<script>

import { ref } from 'vue';
import { parse } from 'date-fns';
import useLoadOptions from '@/hooks/api/loadOptions';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import InteractiveTimeBarChart from '@/components/entities/statistics/InteractiveTimeBarChart.vue';
import axios from '@/services/axios';

export default {
  name: 'RevenueStatisticsIndexPage',
  components: {
    Multiselect,
    InteractiveTimeBarChart,
  },
  setup() {
    const store = ref(null);
    const {
      loadOptions: loadStoreOptions,
      loading: loadingStoreOptions,
    } = useLoadOptions('/stores', 'name');

    const getTooltip = (context) => new Intl.NumberFormat('de-CH', { style: 'currency', currency: context.dataset.label }).format(context.parsed.y);

    const load = async (format, start, end) => {
      const { data } = await axios.post('/statistics/revenue', {
        store: store.value,
        format: format.backend,
        start,
        end,
      });

      const currencyData = {};
      const labels = [];

      data.forEach((d) => {
        if (!Object.keys(currencyData).includes(d.currency)) {
          currencyData[d.currency] = [];
        }
        labels.push(d.slot);
        currencyData[d.currency].push({
          x: parse(d.slot, format.chart, new Date()),
          y: parseFloat(d.amount) / 100,
        });
      });

      const dataset = [];
      Object.entries(currencyData).forEach((entry) => {
        const [currency, dataPoints] = entry;
        dataset.push({
          label: currency,
          data: dataPoints,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 1,
        });
      });
      return dataset;
    };

    return {
      loadStoreOptions,
      loadingStoreOptions,
      store,
      load,
      getTooltip,
    };
  },
};
</script>
