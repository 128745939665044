<template>
  <div>
    <router-tabs :links="links">
      <router-view class="pt-4" />
    </router-tabs>
  </div>
</template>
<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import RouterTabs from '@/components/utils/RouterTabs.vue';

export default {
  components: {
    RouterTabs,
  },
  setup() {
    const { t } = useI18n();
    const links = ref([
      { id: 'tab-overall-statistics', text: t('overallStatistics.label', 2), route: { name: 'overall-statistics' } },
      { id: 'tab-product-statistics', text: t('productStatistics.label', 2), route: { name: 'product-statistics' } },
      { id: 'tab-revenue-statistics', text: t('revenueStatistics.label', 2), route: { name: 'revenue-statistics' } },
      { id: 'tab-discount-statistics', text: t('discountStatistics.label', 2), route: { name: 'discount-statistics' } },
    ]);

    return {
      links,
    };
  },
};
</script>
