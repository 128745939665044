<template>
  <div>
    <interactive-time-bar-chart :load="load" :get-tooltip="getTooltip" />
  </div>
</template>
<script>

import { parse } from 'date-fns';
import InteractiveTimeBarChart from '@/components/entities/statistics/InteractiveTimeBarChart.vue';
import axios from '@/services/axios';

export default {
  name: 'DiscountStatisticsIndexPage',
  components: {
    InteractiveTimeBarChart,
  },
  setup() {
    let discountNames = null;

    const getTooltip = (context) => `${context.dataset.label.discount}: ${new Intl.NumberFormat('de-CH', { style: 'currency', currency: context.dataset.label.currency }).format(context.parsed.y)}`;

    const load = async (format, start, end) => {
      if (discountNames === null) {
        discountNames = {};
        const { data: discountData } = await axios.get('/discounts?pagination=false');
        const discounts = discountData['hydra:member'];
        discounts.forEach((d) => {
          discountNames[d.id] = d.name;
        });
      }

      const { data } = await axios.post('/statistics/discount', {
        format: format.backend,
        start,
        end,
      });

      const currencyData = {};
      const labels = [];

      data.forEach((d) => {
        if (!Object.keys(currencyData).includes(d.discount)) {
          currencyData[d.discount] = {};
        }
        if (!Object.keys(currencyData[d.discount]).includes(d.currency)) {
          currencyData[d.discount][d.currency] = [];
        }
        labels.push(d.slot);
        currencyData[d.discount][d.currency].push({
          x: parse(d.slot, format.chart, new Date()),
          y: parseFloat(d.amount) / 100,
        });
      });

      const datasets = [];
      console.log(currencyData);
      Object.entries(currencyData).forEach((discountEntry) => {
        const [discountId, entries] = discountEntry;
        Object.entries(entries).forEach((entry) => {
          const [currency, dataPoints] = entry;
          datasets.push({
            label: { discount: discountNames[discountId], currency },
            data: dataPoints,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgb(255, 99, 132)',
            borderWidth: 1,
          });
        });
      });
      return datasets;
    };

    return {
      load,
      getTooltip,
    };
  },
};
</script>
